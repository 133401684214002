@import 'variables';
@import 'loader';
.body,
html {
  font-family: $font-family-sans;
  font-size: $font-size-base;
  color: $dark;
  @include font-light;
  background: white;
  //font-smooth: auto;
  //-webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: $font-family-sans;
}

h1 {
  font-size: $h1-font-size;
  @include font-bold;
}

h2 {
  font-size: $h2-font-size;
  @include font-bold;
  letter-spacing: 0.22px;
  line-height: 29px;
}

h3 {
  font-size: $h3-font-size;
  @include font-bold;
  letter-spacing: 0.17px;
  line-height: 22px;
}

.bg-light {
  background-color: rgba($dark, 0.05);
}

hr.light {
  background-color: rgba($dark, 0.05);
}

.icon-xs {
  width: 20px;
  height: 20px;
}

strong {
  color: $dark;
}

.card {
  padding: $spacer;
  background-color: $white;
  border-radius: 13px;
  @include shadow;
}

.mb-md {
  margin-bottom: $spacer * 1.5;
}

.mb-lg {
  margin-bottom: $spacer * 2.5;
}
.broad-text{
  padding: 1rem 2rem 2rem 2rem;
  margin: 0;
  text-align: justify;
}

.mt-lg {
  margin-top: $spacer * 2.5;
}

.delimiter-white {
  width: 1px;
  background-color: rgba($white, 0.2);
  height: 45px;
  &.smaller {
    height: 35px;
    margin: 10px;
  }
}

.my-lg {
  margin-top: $spacer * 1.5;
  margin-bottom: $spacer * 1.5;
}

.my-md {
  margin-top: $spacer;
  margin-bottom: $spacer;
}

.mx-md {
  margin-left: $spacer;
  margin-right: $spacer;
}

.mx-lg {
  margin-left: $spacer * 1.5;
  margin-right: $spacer * 1.5;
}

.mx-sm {
  margin-left: $spacer / 2;
  margin-right: $spacer / 2;
}

.px-sm {
  padding-left: $spacer / 2;
  padding-right: $spacer / 2;
}

.ml-md {
  margin-left: $spacer;
}

.mr-md {
  margin-right: $spacer;
}

.px-md {
  padding-left: $spacer;
  padding-right: $spacer;
}

@mixin primary-box-faded {
  background-color: rgba($white, 0.2);
  border-radius: 10px;
}

@mixin row-between {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.tooltip,
div[class^='tooltip'] {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.tooltip .tooltiptext,
div[class^='tooltip'] .tooltiptext {
  visibility: hidden;
  font-size: 12px;
  width: 120px;
  background-color: $white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.21);
  color: $dark;
  text-align: center;
  border-radius: 10px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after,
div[class^='tooltip'] .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.tooltip:hover .tooltiptext,
div[class^='tooltip']:hover .tooltiptext {
  visibility: visible;
}

.tooltip + span {
  margin: 0 $spacer 0 $spacer/2;
}

.icon-xs-white {
  height: 22px;
  width: 22px;
  color: white;
}

.not-droppable {
  opacity: 0.8;
}

.treated_highlight {
  @include treatment-bg(#6cd0b7);
}

@each $color-key, $color-value in $colors {
  .#{$color-key}_class {
    td {
      user-select: none;
    }
    &.highlight-treatment {
      color: $dark;
      font-weight: 500;
      pointer-events: none;
      td {
        color: $dark;
      }
      @include treatment-bg($color-value);
    }
  }
}

@each $level-key, $level-value in $difficulties {
  .#{$level-key}_level {
    font-weight: 500;
    cursor: pointer;
    .icon {
      background-color: rgba($level-value, 0.15);
      background-image: url('../images/svg/icons/level_#{$level-key}.svg');
      background-repeat: no-repeat;
      border-radius: 15px;
      background-position: center;
      margin-bottom: $spacer;
    }
    &.active {
      color: $white;
      pointer-events: none;
      background-color: rgba($level-value, 0.9);
      .icon {
        background-color: rgba($white, 0.1);
        background-image: url('../images/svg/icons/level_#{$level-key}_active.svg');
      }
    }
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  margin-right: $spacer;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.txt-center {
  text-align: center;
}

.difficulty {
  display: flex;
  flex-direction: column;
  width: 152px;
  height: 152px;
  margin-right: $spacer;
  border-radius: 13px;
  background-color: $white;
  justify-content: center;
  align-items: center;
  color: $dark;
  font-weight: $font-weight-bold;
  @include shadow;
  .icon {
    width: 77px;
    height: 77px;
    text-align: center;
  }
}

.pops {
  transition: opacity 0.8s ease-in;
}
