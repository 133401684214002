.intro-main {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.Intro-center {
    margin: 20px auto 0;
    max-width: 600px;
    text-align: center;
    h2 {
        margin-bottom: 32px;
    }
}

.Intro-justify {
    margin: 20px auto 0;
    max-width: 70%;
    text-align: justify;

    h2 {
        margin-bottom: 32px;
    }
}

.Intro-left {
    margin-top: 20px;
    padding: 10px;
    h2 {
        margin-bottom: 32px;
    }
    section {
        display: flex;
        flex-flow: row nowrap;
        &>div {
            width: 49%;
        }
        img {
            max-width: 100%;
            margin-left: 20px;
        }
    }
}
