@import 'shared';
@import '~three-dots/dist/three-dots.css';
@import '../components/Header/Header.module.scss';

p {
    color: $dark-90;
    font-size: $font-size-base;
    margin-bottom: $spacer;
    line-height: 26px;
}

.App-main {
    background-color: $bg-fill;
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    padding: unset;
    height: inherit;
}

.text-content {
    padding: 0 2rem;
    text-align: justify;
}

.justify-space-between {
    display: flex;
    justify-content: space-between !important;
    height: 97%;
}

.container {
    width: 100%;
    max-width: 1030px;
}

.flex-container {
    width: 100%;
    max-width: 1030px;
    display: flex;
}

.App-center {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    flex-direction: row;
    justify-content: center;
}

.App-dual {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    h2 {
        margin-bottom: 32px;
    }
    section {
        width: 48%;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
}

.shelf {
    width: 1040px;
    height: 60px;
    background-image: url('../images/svg/treatments/shelf.svg');
    background-repeat: no-repeat;
    margin-bottom: 115px;
    margin-top: 90px;
    position: relative;
    .shelf-content {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        top: -85px;
        left: auto;
        right: auto;
        width: 100%;
    }
}

.shelfWelcome {
    @extend .shelf;
    width: 950px !important;
    margin-top: 115px !important;
    margin-bottom: 20px !important;
}

.smallAvatar {
    display: flex;
    justify-content: space-evenly;
}

.greenBackground {
    background: #6bcfb7 !important;
}

.treatment-card {
    width: 177px;
    padding: $spacer;
    text-align: center;
}

.treatment-card:not([draggable]) {
    pointer-events: none;
    opacity: 0.8;
}

.stats-box {
    @include row-between;
    @include primary-box-faded;
    display: flex;
    align-items: center;

    div[class^='tooltip'] {
        margin-left: $spacer;
        margin-right: 10px;
        min-height: 44px;
    }

    span {
        display: inline-block;
    }
}

.footer {
    @extend .header;
    height: 130px;
    background-image: url('../images/svg/icons/Status-bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin-top: auto;
}

.footer-single {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .big {
        font-size: 36px;
        font-weight: $font-weight-bold;
        margin-bottom: 10px;
    }
    .small {
        font-size: $font-size-sm;
        font-weight: $font-weight-medium;
        color: rgba($white, 0.6);
    }
}

.budget-stats {
    margin: 0 10px;
    flex-flow: row nowrap;
}

button,
.btn {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    cursor: pointer;
    border-radius: 10px;
    width: auto;
    height: 44px;
    letter-spacing: 0.15px;
    line-height: 19px;
    color: $primary;
    background-color: $white;
    border-color: transparent;
    vertical-align: center;
    padding: 4px 22px 2px 22px;

    &:focus {
        outline: none;
    }
    &:hover {
        background-color: rgba($white, 0.9);
    }
}

.btn-sm {
    padding: 0 12px;
}

.btn-primary {
    @extend button;
    color: $white;
    background-color: rgba($white, 0.2);
    &:hover {
        background-color: rgba($white, 0.3);
    }
}

.btn-secondary {
    @extend button;
    color: $white;
    background-color: $primary;
    &:hover {
        background-color: rgba($primary, 0.8);
    }
}


/* The Modal (background) */

.modal {
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}


.flex-container {
    display: flex;
    border-radius: 5px;
    @include shadow;
    overflow: hidden;
    background-color: $white;
}

/* Modal Content/Box */

.modal-content {
    background-color: $white;
    margin: 15% auto;
    padding: 20px;
    border-radius: 13px;
    @include shadow;
    box-sizing: border-box;
    width: 280px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .icon {
        width: 77px;
        height: 76px;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $primary;
        background-image: url('../images/svg/icons/modal.svg');
        background-repeat: no-repeat;
        border-radius: 15px;
        background-position: center;
        margin-bottom: $spacer;
    }
    p {
        margin-top: $spacer;
        margin-bottom: 2*$spacer;
        color: $dark;
        font-weight: $font-weight-bold;
    }
}


/* The Close Button */

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.btn-screen {
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
    margin: $spacer / 2;
    background-color: white;
    opacity: 0.9;
    padding: 0;
    box-sizing: border-box;
    @include shadow;
    &:hover {
        background-color: white;
        opacity: 1;
    }
}

.inapp-buttons {
    padding-top: 65px;
    right: 6px;
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 130px;
}


// Full screen and width didnt work properly, keeping this in case we wanna reinstate it

//.btn-screen-full {
//    @extend .btn-screen;
//    background-image: url('../images/svg/icons/full-screen/icon-full.svg');
//}
//
//.btn-screen-shrink {
//    @extend .btn-screen;
//    background-image: url('../images/svg/icons/full-screen/icon.svg');
//}
//
//.btn-width-full {
//    @extend .btn-screen;
//    background-image: url('../images/svg/icons/full-width/icon-width.svg');
//}
//
//.btn-width-shrink {
//    @extend .btn-screen;
//    background-image: url('../images/svg/icons/full-width/icon.svg');
//}

//.full-width {
//    width: 100%;
//    height: 100%;
//    overflow: hidden;
//    /* Hide scrollbars */
//    #root {
//        position: absolute;
//        top: -30px;
//        bottom: 0;
//        left: 0;
//        right: 0;
//    }
//}

.hidden {
    display: none;
}

.difficulty-btns-center {
    margin: 20px auto 0;
    max-width: 400px;
    display: flex;
    text-align: center;
    justify-content: center;
}
