@import '../../styles/shared.scss';

.header {
    width: 1030px;
    height: 85px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    color: $white;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    padding: 18px;
    background-color: $primary;
    border-radius: 13px;
    @include shadow;
    box-sizing: border-box;

    .flex-column {
        h2 {
            margin: 5px 5px 0;
        }
    }

    h2 {
        margin: 5px;
    }
}

.header-start {
    @include row-between;
    align-items: center;

    .level {
        color: rgba($white, 0.8);
        font-size: $font-size-sm;
        font-style: italic;
        font-weight: 300;
        letter-spacing: 2px;
    }
}

.header-msg {
    font-size: $font-size-xl;
    margin: 0 $spacer;
}

.header-btns {
    align-items: center;
    button {
        margin-left: $spacer;
    }
}

.header-bg {
    background-color: $bg-fill-dark;
    height: 85px;
    margin-bottom: -50px;
    width: 100%
}
