@import '../../styles/shared.scss';
.small {
  height: 50px;
  opacity: 0.5;
}

.big {
  height: 90px;
  margin: 0 5px;
}

.bigDraggable {
  @extend .big;
  cursor: move;
}

.note {
  @include shadow;
  height: 60px;
  min-width: 50px;
  background-color: $white;
  margin: 23px auto 0;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  border-radius: 0 0 4px 4px;
  &::before {
    justify-self: flex-start;
    position: absolute;
    background-color: #cdd1d7;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: '';
    left: calc(50% - 5px);
    right: calc(50% - 5px);
    top: -5px;
  }
}

.name {
  font-size: $font-size-sm;
  font-style: italic;
}

.cost {
  margin-top: 5px;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  color: $dark-60;
}
