@import '../../styles/shared.scss';
.ColorCodedCard {
    @include shadow;
    padding: $spacer $spacer * 1.5;
    background-color: $white;
    border-radius: 13px;
    position: relative;
    overflow: hidden;
    margin-bottom: $spacer * 1.5;
    text-align: left;
    &::before {
        left: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        position: absolute;
        content: '';
    }
    h3 {
        margin-top: $spacer / 2;
    }
}

.primary {
    &::before {
        background-color: $primary;
    }
}

.secondary {
    &::before {
        background-color: $secondary;
    }
}

.contrast {
    &::before {
        background-color: $contrast;
    }
}