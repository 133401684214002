@import '../../styles/shared.scss';
.cardPatient {
  font-size: $font-size-sm;
  width: 290px;
  height: 340px;
  padding-bottom: $spacer * 1.5;
  @extend .card;
  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.cardPatientStat {
  @extend .cardPatient;
  td {
    padding: 12px;
    color: $dark !important;
  }
  height: 250px;
}

.cardHeader {
  border-bottom: 1px solid $light-ultra;
  padding-bottom: $spacer;
}

.avatar {
  display: inline-block;
  vertical-align: middle;
  margin-right: 17px;
  width: 55px;
  height: 55px;
  border-radius: 15px;
  background-color: $bg-fill;
  overflow: hidden;
  svg {
    width: 100%;
    height: 100%;
  }
}

.styledTable {
  border-collapse: collapse;
  td {
    padding: 12px 10px 10px 8px;
    font-weight: $font-weight-light;
    color: $dark-60;
  }
  th {
    padding: 9px 9px;
    font-weight: $font-weight-medium;
    &:not(:first-child) {
      border-bottom: 1px solid $light-ultra;
    }
  }
  tr {
    border-radius: 10px;
  }
}

.rowTitle {
  padding: 12px 6px 12px 6px;
  min-width: 100px;
  font-weight: $font-weight-medium !important;
  color: $dark !important;
  img {
    vertical-align: middle;
    margin-right: 13px;
    width: 20px;
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: $spacer;
    vertical-align: middle;
  }
}

.styledTableStats {
  @extend .styledTable;
  width: 100%;
}

.rowTitleLong {
  width: 100%;
  @extend .rowTitle;
  & + td {
    text-align: right;
  }
  & td {
    padding: 13px 0px 13px 8px;
  }
  & td:first-child {
    display: flex;
    align-items: center;
  }
  img {
    vertical-align: middle;
    margin-right: $spacer;
    width: 20px;
  }
  svg {
    vertical-align: middle;
    margin-right: $spacer;
    width: 24px;
    height: 24px;
  }
  & td:last-child {
    color: $dark;
    text-align: right;
  }
}
