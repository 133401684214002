.loader {
    height: 20px;
    width: 250px;
}

.loader--dot {
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: black;
    position: absolute;
}

.loader--dot:first-child {
    background-color: #fb816b;
    animation-delay: 0.5s;
}

.loader--dot:nth-child(2) {
    background-color: #bad200;
    animation-delay: 0.4s;
}

.loader--dot:nth-child(3) {
    background-color: #bad200;
    animation-delay: 0.4s;
}

.loader--dot:nth-child(4) {
    background-color: #61b6e1;
    animation-delay: 0.2s;
}

.loader--dot:nth-child(5) {
    background-color: #a681ef;
    animation-delay: 0.1s;
}

.loader--dot:nth-child(6) {
    background-color: #6cd0b7;
    animation-delay: 0s;
}

.loader--text {
    left: 0;
    right: 0;
    width: 11rem;
    display: inline;
}

.loader--text:after {
    content: ' ';
    font-weight: bold;
    animation-name: loading-text;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes loader {
    15% {
        transform: translateX(0);
    }
    45% {
        transform: translateX(230px);
    }
    65% {
        transform: translateX(230px);
    }
    95% {
        transform: translateX(0);
    }
}

@keyframes loading-text {
    0% {
        content: '';
    }
    25% {
        content: '.';
    }
    50% {
        content: '..';
    }
    75% {
        content: '...';
    }
}