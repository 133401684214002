// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
// Color system
$white: #fff !default;
$dark: rgb(64, 77, 102) !default;
$dark-60: rgba(64, 77, 102, 0.6) !default;
$dark-90: rgba(64, 77, 102, 0.9) !default;
$black: #000 !default;
$primary: rgb(107, 207, 183);
$primary-20: rgba($primary, 0.2);
$secondary: rgb(251, 129, 107);
$contrast: #a681ef;
$bg-fill: #f9fafc;
$bg-fill-dark: #ebedef;
$light-ultra: rgba($dark, 0.05);
// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans: 'Roboto',
sans-serif;
$font-size-base: 18px !default; // Assumes the browser default, typically `16px`
$font-size-sm: 14px !default;
$font-size-lg: 20px !default;
$font-size-xl: 24px !default;

//font-color
// Font-weight
$font-weight-light: 400 !default;
$font-weight-medium: 600 !default;
$font-weight-bold: 800 !default;
$font-weight-base: $font-weight-light !default;
$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;
$h1-font-size: 30px !default;
$h2-font-size: 24px !default;
$h3-font-size: 20px !default;
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 15px !default;
$spacers: ( 0: 0, 1: $spacer / 4, 2: $spacer / 2, 3: $spacer, 4: $spacer * 1.5, 5: $spacer * 3, ) !default;
// Body
//
// Settings for the `<body>` element.
$body-bg: $bg-fill !default;
$body-color: $dark !default;
// Tables
@mixin font-light {
    font-weight: $font-weight-light;
}

@mixin font-normal {
    font-weight: $font-weight-medium;
}

@mixin font-italic {
    font-style: italic;
}

@mixin font-bold {
    font-weight: $font-weight-bold;
}

@mixin txt-faded {
    color: $dark-60;
}

@mixin shadow {
    box-shadow: 0 10px 32px 0 rgba(0, 0, 0, 0.07);
}

@mixin treatment-bg($color-value) {
    td {
        background-color: rgba($color-value, 0.15) !important;
    }
    & td:first-child {
        border-top-left-radius: 10px;
        background-color: rgba($color-value, 0.15) !important;
    }
    & td:last-child {
        border-top-right-radius: 10px;
        background-color: rgba($color-value, 0.15) !important;
    }
    & td:first-child {
        border-bottom-left-radius: 10px;
        background-color: rgba($color-value, 0.15) !important;
    }
    & td:last-child {
        border-bottom-right-radius: 10px;
        background-color: rgba($color-value, 0.15) !important;
    }
}

$colors: ( healthy: #fb816b, cold: #6cd0b7, flu: #a681ef, bronchitis: #61b6e1, lung_cancer: #bad200, );
$difficulties: ( easy: #6bcfb7, medium: #61b6e1, hard: #fb816b, );
